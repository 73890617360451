import { HealthCategory } from "@my/api"
import { paramThreadIdNew, QueryParams } from "./queryParams"

export const Routes = {
  chat: "/chat",
  newThread: `/chat?${QueryParams.threadId}=${paramThreadIdNew}`,
  settings: "/settings",
  configureCheckIns: "/settings/check-ins",
  configureHealthKit: "/settings/health-kit",
  featureModule: "/settings/feature-module",
  profile: "/profile",
  healthDetails: "/profile/health-details",
  food: "/profile/food",
  addFood: "/profile/food/add-food",
  logout: "/logout",
  explore: "/explore",

  createRouteForThread: (threadId?: string) => {
    const queryString = buildQueryString({
      [QueryParams.threadId]: threadId,
    })
    return `/chat${queryString}`
  },

  createRouteForProfileCategory: (categoryName: HealthCategory) => {
    return `/profile/category-summary/${categoryName}`
  },

  createRouteForProfileSubcategory: (
    subcategoryId: string,
    categoryName: HealthCategory,
    displayName?: string,
  ) => {
    const queryString = buildQueryString({
      [QueryParams.categoryName]: categoryName,
      [QueryParams.displayName]: displayName,
    })
    return `/profile/subcategory-details/${subcategoryId}${queryString}`
  },

  createRouteForProfileViewDataPoint: (subcategoryId: string) => {
    return `/profile/view-data-point/${subcategoryId}`
  },

  createRouteForProfileAddDataPoint: (subcategoryId: string) => {
    return `/profile/add-data-point/${subcategoryId}`
  },
} as const

function buildQueryString(params: Partial<Record<keyof typeof QueryParams, string | undefined>>) {
  const queryString = Object.entries(params)
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${encodeURIComponent(value as string)}`)
    .join("&")
  return queryString ? `?${queryString}` : ""
}
